<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>


</style>